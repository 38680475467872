// import DaneSummary from "./components/DaneSummary2";

function App() {
  return (
    <div>
      Mail vulnerability & other stuff
    </div>
    
    // <div className="container">
    //   <h1 className="display-1">Ryan's Checker</h1>
    //   <DaneSummary />
    // </div>

    // <Provider store={store}>
    //   <Container text>
    //     <Header as="h1" content="Ryan's Checker" />
    //     <CheckDomain />
    //     <Divider />
    //     <MtaStsSummary />
    //     <DaneContainer />
    //   </Container>
    // </Provider>
  );
}

export default App;
